import { Box } from "@xstyled/styled-components";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import Hero from "../../components/common/Hero/hero";
import ContainerNarrow from "../../components/layout/ContainerNarrow/container-narrow";
import Layout from "../../components/layout/Main/main";
import SEO from "../../components/seo";
import Headline from "../../components/typography/Headline/headline";

const Post = ({ data }) => {
    const { markdownRemark: post } = data;
    return (
        <Layout>
            <SEO
                title={post.frontmatter.title}
                description={post.frontmatter.description}
                image={post.frontmatter.featuredImage.publicURL}
            />
            <Box mb={5}>
                <Hero
                    fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
                />
            </Box>
            <ContainerNarrow>
                <Box>
                    <Headline>{post.frontmatter.title}</Headline>
                </Box>
                <Box mb={5} className="template-post">
                    <div dangerouslySetInnerHTML={{ __html: post.html }} />
                </Box>
            </ContainerNarrow>
        </Layout>
    );
};

Post.propTypes = {
    data: PropTypes.object,
};

export const pageQuery = graphql`
    query BlogPostByPath($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                path
                title
                featuredImage {
                    publicURL
                    childImageSharp {
                        fluid(maxWidth: 2000) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;

export default Post;
